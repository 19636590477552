<div style="padding: 20px" *ngIf="!isLoading">
  <ng-container *ngIf="offers.length; else noOffers">
    <h3 class="mb-3 text-center">Offerte Disponibili</h3>

    <table class="table">
      <thead>
        <tr>
          <th scope="col">Offerta</th>
          <th scope="col">Prezzo</th>
          <th scope="col">Data di Creazione</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let offer of offers">
          <td>
            <a routerLink="/app/offer/{{offer.id}}" target="_blank">{{ offer.codice_interno }}</a>
          </td>
          <td>{{ offer.prezzo | currency:'EUR' : 'symbol' : '1.2-2' }}</td>
          <td>{{ offer.created | date }}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>

  <ng-template #noOffers>
    <h4 class="m-0 text-center">Nessuna offerta disponibile per questo articolo</h4>
  </ng-template>
</div>

<div *ngIf="isLoading" class="loader">
  <mat-spinner></mat-spinner>
  <p style="color: black" class="mb-0">Attendere...</p>
</div>