import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/service/auth.service';
import { ApiService } from '../../_service/api.service';
import { ENDPOINTS, UrlService } from '../../_service/url.service';
import Jsona from 'jsona';
import { DataManagmentService } from '../../_service/data-managment.service';

const dataFormatter = new Jsona();

@Component({
  selector: 'app-offer-selection',
  templateUrl: './offer-selection.component.html',
  styleUrls: ['./offer-selection.component.scss']
})
export class OfferSelectionComponent implements OnInit {
  offers: any[] = [];
  isLoading: boolean = true;

  currentHighestRole: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { data: any },
    private authService: AuthService,
    private apiSer: ApiService,
    private url: UrlService,
    private GLOBAL: DataManagmentService
  ) { }

  async ngOnInit() {
    this.GLOBAL.currentUserData.subscribe(user => {
      if (user.role) {
        this.currentHighestRole = user.role;
      }
    });

    if (this.currentHighestRole.value === 'cliente') {
      const articleID = this.data.data.article.id;
      const username = this.authService.currentUserValue.name;

      let queryParams = {
        'filter[articolo.id]': articleID,
        'filter[offerta.cliente.user.name]': username,
        include: 'offerta',
        sort: '-created'
      }

      // Keep track of the call loop
      let keepCalling = true;
      let next = '';

      while (keepCalling) {
        await this.getOfferRows(queryParams, next).then(nextLink => {
          if (nextLink) {
            next = nextLink;
          } else {
            keepCalling = false;
            next = '';
          }
        })
      }

      this.isLoading = false;
    }
  }

  getOfferRows(queryParams?: any, next?: string) {
    return new Promise<string|undefined>((resolve, reject) => {
      this.apiSer
        .getRequest(next ? next : this.url.getBackendUrl() + ENDPOINTS.righe_offerte, null, next ? undefined : queryParams)
        .subscribe({
          next: res => {
            const data = dataFormatter.deserialize(res) as any[];

            if (data.length) {
              data.forEach(row => {
                this.offers.push({
                  id: row.offerta.id,
                  codice_interno: row.offerta.codice_interno,
                  prezzo: row.prezzo,
                  created: row.created
                })
              })
            }

            if (res.links.next) {
              resolve(res.links.next.href);
            } else {
              resolve(undefined);
            }
          },
          error: err => {
            reject(err);
          }
        })
    })
  }
}
