import { FormGroup, FormControl } from '@angular/forms';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { BehaviorSubject, Subscription, debounceTime, startWith } from 'rxjs';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { MatSelectFilterComponent } from 'mat-select-filter';
import { AuthService } from 'src/app/core/service/auth.service';
import { RUOLI_DISPONIBILI } from '../../_data/availableRoles';


export enum GenericTableHeaderFilterType {
  TEXT = 'text',
  NUMBER = 'number',
  SELECT = 'select',
  DATE = 'date',
  DATE_RANGE = 'date_range',
  NUMBER_RANGE = 'number_range',
  CHECKBOX = 'checkbox'
}

export enum DynamicLoadEndpoint {
  USERS = 'usersNextURL',
  ARTICOLI = 'articlesNextURL',
  PRODOTTI = 'productsNextURL',
  FARMADATI = 'farmadataNextURL',
  FARMADATI_ALIQUOTE = 'aliquoteNextURL',
  FARMADATI_CONTENITORI = 'contenitoriNextURL',
  FORNITORI = 'suppliersNextURL',
  CLIENTI = 'clientsNextURL',
  INDIRIZZI = 'addressesNextURL',
  ORDINI_FORNITORI = 'suppOrdersNextURL',
  ORDINI_CLIENTI = 'clientOrdersNextURL',
  RIGHE_ORDINI = 'rowsNextURL'
}

export interface GenericTableHeaderDateFilterOptions {
  startAt: Date;
  min: Date;
  max: Date;
}

/**
 * Interfaccia di configurazione per i filtri in tabella.
 * @param label - Il nome del filtro mostrato in tabella.
 * @param name - Il nome del filtro nel formGroup.
 * @param type - Il tipo di filtro da inserire ({@link GenericTableHeaderFilterType}).
 * @param labelClassList - Opzionale. Lista delle classi da applicare alla label del filtro.
 * @param inputClassList - Opzionale. Lista delle classi da applicare all'elemento input del filtro.
 * @param iconClassList - Opzionale. Lista delle classi da applicare all'icona del filtro.
 * @param icon - Opzionale. Specifica la mat-icon da utilizzare per gli input di tipo {@link GenericTableHeaderFilterType.TEXT TEXT} e {@link GenericTableHeaderFilterType.NUMBER NUMBER}.
 * @param selectOptions - Opzionale. Un array di {@link SelectOption} che specifica le opzioni selezionabili di un filtro di tipo {@link GenericTableHeaderFilterType.SELECT SELECT}.
 * @param multipleSelection - Opzionale. Un booleano che specifica se abilitare la selezione multipla in un filtro di tipo {@link GenericTableHeaderFilterType.SELECT SELECT}.
 * @param disableFilter - Opzionale. Un booleano che specifica se disabilitare il campo di ricerca in un filtro di tipo {@link GenericTableHeaderFilterType.SELECT SELECT}.
 * @param dynamicLoad - Opzionale. Un booleano che specifica se abilitare il caricamento dinamico delle opzioni selezionabili in un filtro di tipo {@link GenericTableHeaderFilterType.SELECT SELECT}.
 * @param dynamicSearchField - Opzionale. Una stringa o un array di stringhe che indica su quali campi effettuare la ricerca sulla select.
 * @param dynamicFilteringField - Opzionale. Una stringa che indica su quale campo dell'entità impostare il valore del singolo elemento sulla select.
 * @param endpoint - Opzionale. Enumerazione di {@link DynamicLoadEndpoint} che specifica quale endpoint utilizzare per il caricamento dinamico in un filtro di tipo {@link GenericTableHeaderFilterType.SELECT SELECT}.
 * @param defaultValue - Opzionale. Specifica il valore di default da assegnare al formControl.
 * @param excludedRoles - Opzionale. Specifica un array di ruoli per i quali nascondere il filtro.
 */
export interface GenericTableHeaderFilterConfig {
  label: string;
  name: string;
  type: GenericTableHeaderFilterType;
  labelClassList?: string;
  inputClassList?: string;
  iconClassList?: string;
  icon?: string;
  selectOptions?: SelectOption[];
  /* dateOptions?: GenericTableHeaderDateFilterOptions; */
  multipleSelection?: boolean;
  disableFilter?: boolean;
  dynamicLoad?: boolean;
  dynamicSearchField?: string | string[];
  dynamicFilteringField?: string;
  endpoint?: string;
  defaultValue?: any;
  excludedRoles?: string[];
}

export interface SelectOption {
  label: string;
  value: string;
}

interface QueryParam {
  query: string,
  value: string
}

@Component({
  selector: 'app-generic-table-header',
  templateUrl: './generic-table-header.component.html',
  styleUrls: ['./generic-table-header.component.scss']
})
export class GenericTableHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() title: string;
  @Input() FiltersTitle: string;
  @Input() set configuration(configuration: BehaviorSubject<GenericTableHeaderFilterConfig[]>) {
    configuration.subscribe(config => {
      if (!this.formReady) {
        this.buildForm(config);
      }
      this._configuration = config;
      this._configuration.forEach((obj, i) => {
        if (obj.type === 'select') {
          this.selectFilters[i] = { list: obj.selectOptions, filteredList: obj.selectOptions };
        }

        if (obj.dynamicLoad) {
          this.dynamicSelects.add(i);
        }
      });
    })
  };
  @Input() formUrl: string;
  @Input() displayFilters: boolean = false;
  @Input() isFiltering: BehaviorSubject<boolean>;
  @Output() addButtonClicked = new EventEmitter();
  @Output() filterChanged = new EventEmitter<any>();
  @Output() loadMore = new EventEmitter<{ endpoint: string, index: number }>();
  @Output() filterSearch = new EventEmitter<{ value: string, endpoint: string, index: number, selectedElements?: any[] }>();
  @Output() getMissingFilters = new EventEmitter<{ value: string | string[], endpoint: string, index: number }>();
  @ViewChildren('selectFilter') matSelectFilters: QueryList<MatSelectFilterComponent>;

  form: FormGroup;
  _configuration: GenericTableHeaderFilterConfig[];
  //displayFilters = false;
  addUrl: string;
  queryParams: QueryParam | undefined;
  selectFilters = [];
  matFilters = [];
  filtersSubscriptions: Subscription[] = [];
  dynamicSelects = new Set<number>();
  scrollingSubscription: Subscription;
  formReady: boolean = false;
  currentUserRoles: string[];
  writePermission: boolean = false;

  constructor(
    private scrollDispatcher: ScrollDispatcher,
    private cd: ChangeDetectorRef,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.currentUserRoles = this.authService.currentUserValue.roles;
    this.currentUserRoles.forEach(role => {
      const hasWritePermission = RUOLI_DISPONIBILI.find(r => r.value === role)?.writePermissions;
      if (hasWritePermission) {
        this.writePermission = true;
      }
    })

    const url = this.formUrl ? this.formUrl.split('?') : null;
    this.addUrl = url ? `/${url[0]}/create` : '';
    if (url && url[1]) {
      const queryParams = url[1].split('=');
      this.queryParams = {
        query: queryParams[0],
        value: queryParams[1]
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.matSelectFilters.length) {
      this.matFilters = this.matSelectFilters.toArray()
    }

    this.matSelectFilters.changes.subscribe((data: QueryList<MatSelectFilterComponent>) => {
      this.matFilters = data.toArray();
    })

    this.scrollingSubscription = this.scrollDispatcher.scrolled(100).subscribe((event: CdkScrollable) => {
      if (event) {
        let scrollerId = event.getElementRef().nativeElement.id;
        let bottomOffset = event.measureScrollOffset('bottom');
        if (bottomOffset === 0 && !this.isFiltering.value) {
          let index = scrollerId.split('-')[2];
          this.loadMore.emit({
            endpoint: this._configuration[index].endpoint,
            index: +index
          });
        }
      }
    })
  }

  /* ngOnChanges(changes: SimpleChanges): void {
    if (changes.configuration) {
      this.configuration.next(changes.configuration.currentValue)
    }
  } */

  ngOnDestroy(): void {
    this.filtersSubscriptions.forEach(sub => sub.unsubscribe());
    this.scrollingSubscription.unsubscribe();
  }

  getStorageFilters(configuration: GenericTableHeaderFilterConfig[]) {
    /* Check if sessionStorage contains filters data for current table */
    if (window.sessionStorage.getItem(`${this.title} filters`) === 'true') {
      let filters = {};

      /* Build the filters object dynamically */
      configuration.forEach((config, i) => {
        let name = config.name;
        let type = config.type;
        let multipleSelection = config.multipleSelection;
        let filter = window.sessionStorage.getItem(`[${this.title} filter] ${name}`)

        if (filter) {
          this.displayFilters = true;

          if (type === 'date_range') {
            filters[name] = { start: new Date(filter.split(',')[0]), end: new Date(filter.split(',')[1]) };
            this.form.get(name).setValue({ start: new Date(filter.split(',')[0]), end: new Date(filter.split(',')[1]) });
          } else if (type === 'number_range') {
            filters[name] = { min: filter.split(',')[0], max: filter.split(',')[1] };
            this.form.get(name).setValue({ min: filter.split(',')[0], max: filter.split(',')[1] });
          } else if (multipleSelection) {
            filters[name] = filter.split(',');
            this.form.get(name).setValue(filter.split(','));
          } else {
            filters[name] = filter;
            this.form.get(name).setValue(filter);
          }

          if (type === 'select' && config.dynamicLoad) {
            if (!config.multipleSelection) {
              if (config.selectOptions.length && !config.selectOptions.filter(option => option.label === filters[name]).length) {
                this.getMissingFilters.emit({
                  value: filters[name],
                  endpoint: config.endpoint,
                  index: i
                });
              } else {
                let optionIndex = config.selectOptions.findIndex(option => option.label === filters[name]);
                if (optionIndex > 4) {
                  let elem = config.selectOptions[optionIndex];
                  config.selectOptions.splice(optionIndex, 1);
                  config.selectOptions.unshift(elem);
                }
              }
            } else {
              let missingLabels = [];
              filters[name].forEach(label => {
                if (!config.selectOptions.filter(option => option.label === label).length) {
                  missingLabels.push(label);
                }
              });

              if (config.selectOptions.length && missingLabels.length) {
                this.getMissingFilters.emit({
                  value: filters[name],
                  endpoint: config.endpoint,
                  index: i
                });
              } else {
                let indexes = [];
                filters[name].forEach(label => {
                  indexes.push(config.selectOptions.findIndex(option => option.label === label));
                });
                indexes = indexes.filter(index => index > 4);

                if (indexes.length) {
                  indexes.forEach(index => {
                    let elem = config.selectOptions[index];
                    config.selectOptions.splice(index, 1);
                    config.selectOptions.unshift(elem);
                  })
                }
              }
            }

          }
        } else {
          filters[name] = type === 'date_range' ? { start: '', end: '' } : type === 'number_range' ? { min: 0, max: 1000 } : '';
        }
      })

      this.filterChanged.emit(filters);
    }
  }

  resetFilters() {
    let defaultValues = {};
    this._configuration.filter(config => config.type === 'number_range').forEach(filter => {
      if (filter) {
        defaultValues[`${filter.name}`] = { min: 0, max: 1000 };
      }
    });
    this.form.reset(defaultValues);
    this.filterChanged.emit(this.form.value);
  }

  applyFilters() {
    this.filterChanged.emit(this.form.value);
  }

  setupSearch(index: number) {
    let selectsArray = Array.from(this.dynamicSelects);
    let filterIndex = selectsArray.findIndex(i => i === index);
    let elem = this.matFilters[filterIndex];

    if (elem && !this.filtersSubscriptions[filterIndex]) {
      let firstValue = elem.searchForm.value;
      this.isFiltering.next(true);
      this.filtersSubscriptions[filterIndex] = elem.searchForm.valueChanges.pipe(debounceTime(500), startWith(firstValue)).subscribe(value => {
        let selectedElements = this.form.value[this._configuration[index].name];

        this.filterSearch.emit({
          value,
          endpoint: this._configuration[index].endpoint,
          index,
          selectedElements
        });
      })
    }
  }

  private buildForm(configuration: GenericTableHeaderFilterConfig[]) {
    this.form = new FormGroup({});
    configuration.forEach((config) => {
      if (config.type === GenericTableHeaderFilterType.DATE_RANGE) {
        this.form.addControl(config.name, new FormGroup({
          start: new FormControl(''),
          end: new FormControl('')
        }));
      } else if (config.type === GenericTableHeaderFilterType.NUMBER_RANGE) {
        this.form.addControl(config.name, new FormGroup({
          min: new FormControl(0),
          max: new FormControl(1000)
        }));
      } else {
        this.form.addControl(config.name, new FormControl(config.defaultValue ?? ''));
      }
    });

    this.getStorageFilters(configuration);
    this.formReady = true;
  }

  formatLabel(value: number) {
    if (value === 1000) {
      return '€1000+';
    } else {
      return '€' + value;
    }
  }

  setFocus(isOpened: boolean, elementID: string) {
    if (isOpened) {
      const elem = document.getElementById(elementID);
      const input = elem.firstElementChild.firstElementChild.firstElementChild as HTMLInputElement;
      input.focus();
    }
  }

  checkRoles(excludedRoles?: string[]) {
    let hideThis = false;
    this.currentUserRoles.forEach(role => {
      if (excludedRoles?.includes(role)) {
        hideThis = true;
      }
    })

    return hideThis;
  }
}
