import { Component, EventEmitter, Input, Output, ViewChild, AfterViewInit, OnInit, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { DataManagmentService } from '../../_service/data-managment.service';


export enum TableExporterColumnType {
  PLAIN_TEXT = 'PLAIN_TEXT', // Modalità da usare quando si vuole inserire all'interno della cella un testo
  HTML_TEXT = 'HTML_TEXT', // Modalità da usare quando si vuole inserire all'interno della cella del codice HTML
  STATUS = 'STATUS', // Modalità da usare quando si vuole inserire all'interno della cella un campo di tipo stato
  BOOL = 'BOOL', // Modalità da usare quando si vuole inserire all'interno della cella un campo di tipo Booleano
  CURRENCY = 'CURRENCY', // Modalità da usare quando si vuole inserire all'interno della cella un campo di tipo valuta
  ACTION = 'ACTION', // Modalità da usare quando si vuole inserire all'interno della cella un pulsante per effettuare un'azione
  LOADER = 'LOADER', // Modalità da usare quando si vuole inserire all'interno della cella un pulsante per effettuare un'azione
  SELECTION = 'SELECTION' // Modalità da usare quando si vuole inserire all'interno della cella una checkbox per selezionare la riga
}

/**
 * Interfaccia di configurazione delle colonne in tabella.
 * @param id - ID della colonna (campo matColumnDef del ng-container)
 * @param header - Testo da mostrare come header della colonna
 * @param fieldName - Nome del campo su cui lavorare all'interno della cella
 * @param headerClassList - Lista delle classi da applicare all'header della tabella in corrispondenza della colonna
 * @param fieldClassList - Lista delle classi da applicare all'elemento della tabella in corrispondenza della colonna
 * @param sortable - Specifica se la colonna è ordinabile o meno
 * @param sortField - Opzionale. Specifica il nome del campo su cui filtrare se la colonna è filtrabile
 * @param tooltip - Opzionale. Specifica il nome del campo su cui filtrare se la colonna è filtrabile
 * @param type - Tipo di colonna da inserire ({@link TableExporterColumnType})
 * @param showMobile - Opzionale. Specifica se nascondere la colonna nella vista mobile
 * @param actions - Opzionale. Array di {@link TableAction} da includere nella colonna di tipo ACTION
 * @param hasTransformer - Booleano per controllare se bisogna applicare la transformFieldFunction o meno
 * @param transformFieldFunction - Opzionale. Funzione che opera come una pipe sul valore del campo indicizzato dal fieldName. Prende in input il valore (es. row[fieldname]) e deve restituire una stringa.
 * @param excludedRoles - Opzionale. Specifica un array di ruoli per i quali nascondere la colonna.
 */
export interface TableExporterColumnConfiguration {
  id: string;
  header: string;
  fieldName: string;
  headerClassList: string;
  fieldClassList: string;
  sortable: boolean;
  sortField?: string;
  tooltip?: string;
  type: TableExporterColumnType;
  showMobile?: boolean;
  actions?: TableAction[];
  hasTransformer: boolean;
  transformFieldFunction?: (object: any) => string | number | boolean;
  loaderOption?: string;
  excludedRoles?: string[];
}

/**
 * Interfaccia di configurazione per export server-side
 * @param columnName - Specifica il nome della colonna esportata
 * @param exportFieldName - Nome del campo con percorso completo in base alla relazione dell'entità, utilizzato per specificare i campi nell'export server-side (es. market.title)
 * @param exportDateFormat - Opzionale. Specifica che tipo di dato estrapolare dalla data per l'export server-side
 */
export interface TableExportConfiguration {
  columnName: string; // Specifica il nome della colonna esportata
  exportFieldName: string; // Nome del campo con percorso completo in base alla relazione dell'entità, utilizzato per specificare i campi nell'export server-side (es. market.title)
  exportDateFormat?: 'DATE' | 'TIME'; // Specifica che tipo di dato estrapolare dalla data per l'export server-side
}

/**
 * Interfaccia di configurazione per le colonne di tipo ACTION
 * @param actionIcon - Nome della mat-icon da usare all'interno del campo action (es. edit)
 * @param performAction - Funzione che viene eseguita in corrispondenza del click sul bottone dell'azione. Prende in input l'evento di click, l'id della colonna, l'indice della riga e l'oggetto (es. row[fieldname]) su cui si basa la riga
 */
export interface TableAction {
  actionIcon: string; // Nome della mat-icon da usare all'interno del campo action (es. edit)
  performAction: (event: Event, columnID: string, index: number, object: any) => void | string; // Funzione che viene eseguita in corrispondenza del click sul bottone dell'azione. Prende in input l'evento di click, l'id della colonna, l'indice della riga e l'oggetto (es. row[fieldname]) su cui si basa la riga
  excludedRoles?: string[] // Array di stringhe contenente i ruoli per i quali nascondere l'azione
}

export interface TableExporterSortEvent {
  active: string;
  direction: string;
}

export interface TableExporterPaginationEvent {
  previousPageIndex: number,
  pageIndex: number,
  pageSize: number,
  length: number
}

@Component({
  selector: 'app-generic-exporter-table',
  templateUrl: './generic-exporter-table.component.html',
  styleUrls: ['./generic-exporter-table.component.scss']
})
export class GenericExporterTableComponent implements AfterViewInit, OnInit, OnChanges {
  @Input() tableName: string;
  @Input() data: any[][];
  @Input() set configuration(columns: TableExporterColumnConfiguration[]) {
    console.log('Configuration loaded:', columns);
    this._configuration = columns;
    this.displayedColumns = columns.map((col) => col.id);
  }
  @Input() formUrl: string;
  @Input() totalResults = 0;
  @Input() currentIndex: number = 0;
  @Input() exportColumnWidths: number[];
  @Input() exportHiddenColumns: number[];
  @Input() showExportButtons: boolean = true;
  @Input() pageSizeOption: number[] = [20, 50];
  @Output() pageSizeChange = new EventEmitter<number>();
  @Output() indexChange = new EventEmitter<number>();
  @Output() sortData = new EventEmitter<TableExporterSortEvent>();
  @Output() loadNextPage = new EventEmitter<string>();
  @Output() exportClicked = new EventEmitter();
  @Output() exportCSVClicked = new EventEmitter();
  @Output() updateTransfersStatus = new EventEmitter<string[]>();

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns: string[] = [];
  currentPageSize: number = 20;
  _configuration: TableExporterColumnConfiguration[];
  resultsLength = 0;
  oldPageSize = 0;
  selectedElements: any[] = [];
  showTransferStatusButton: boolean = true;
  currentHighestRole: any;

  modifyUrl: string;

  constructor(
    private GLOBAL: DataManagmentService
  ) { }

  ngOnInit(): void {
    this.modifyUrl = `/${this.formUrl}/`;

    this.GLOBAL.currentUserData.subscribe(user => {
      if (user.role) {
        this.currentHighestRole = user.role;
      }
    });
  }

  ngAfterViewInit(): void {
    this.oldPageSize = this.paginator.pageSize;

    if (window.sessionStorage.getItem(`[${this.tableName} sort] Column`)) {
      let sorting: TableExporterSortEvent = {
        active: window.sessionStorage.getItem(`[${this.tableName} sort] Column`),
        direction: window.sessionStorage.getItem(`[${this.tableName} sort] Direction`)
      }

      setTimeout(() => {
        this.sort.sort({
          id: sorting.active,
          start: sorting.direction as SortDirection,
          disableClear: false
        });
      }, 1000);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentIndex && this.paginator) {
      this.paginator.pageIndex = this.currentIndex;
      this.setSelectedRows();
    }
  }

  simpleSort(event: TableExporterSortEvent) {
    if (this.selectedElements.length) {
      this.selectedElements = []
    }
    this.paginator.pageIndex = 0;
    this.indexChange.emit(0);
    this.sortData.emit(event);
  }

  setPagination(event: TableExporterPaginationEvent) {
    console.log(event)
    this.indexChange.emit(event.pageIndex);
    this.currentPageSize = event.pageSize;
    if (event.pageIndex > event.previousPageIndex) {
      if (event.pageIndex === event.previousPageIndex + 1) {
        this.loadNextPage.emit('next');
      } else {
        this.loadNextPage.emit('last');
      }
    } else if (event.pageIndex < event.previousPageIndex) {
      if (event.pageIndex === event.previousPageIndex - 1) {
        this.loadNextPage.emit('prev');
      } else {
        this.loadNextPage.emit('first');
      }
    }

    if (event.pageSize !== this.oldPageSize) {
      this.oldPageSize = event.pageSize;
      this.pageSizeChange.emit(this.oldPageSize);
      if (this.currentIndex > 0) {
        this.paginator.firstPage();
      }
    }

    if (this.selectedElements.length) {
      this.selectedElements = []
    }
  }

  selectRow(selected: boolean, row: any) {
    console.log(row, selected);

    if (selected) {
      this.selectedElements.push(row);
    } else {
      const index = this.selectedElements.findIndex(elem => elem.id === row.id);
      if (index !== -1) {
        this.selectedElements.splice(index, 1);
      }
    }

    if (this.selectedElements.some(elem => elem.stato_trasmissione !== 'spedita')) {
      this.showTransferStatusButton = false;
    } else {
      this.showTransferStatusButton = true;
    }
  }

  setSelectedRows() {
    setTimeout(() => {
      if (this.selectedElements.length) {
        if (this.data[this.currentIndex]) {
          let selectedIDs = this.selectedElements.map(elem => { return elem.id });
          this.data[this.currentIndex].forEach(elem => {
            if (selectedIDs.includes(elem.id)) {
              elem.rowSelected = true;
            }
          })
        } else {
          this.setSelectedRows();
        }
      }
    }, 500);
  }

  generateCustomCSV() {
    if (this.selectedElements.length) {
      // Set the amount of columns in the CSV
      let csvRows = [];

      console.log(this.selectedElements)

      this.selectedElements.forEach(elem => {
        const entity = elem.is_rimessa_diretta ? elem.fornitore_rev : elem.cliente_rev;
        const address = entity.indirizzi.find(address => { return address.tipo_indirizzo === 'fatturazione' && address.is_default });
        let p_iva = entity.partita_iva ? entity.partita_iva.split('-', 2) : ['-'];

        const trasmissione = {
          type: 'T', //A
          client_code: entity.ex_code ? entity.ex_code : entity.codice_interno, //B
          ragione_sociale: entity.ragione_sociale ? entity.ragione_sociale.toUpperCase() : '', //C
          indirizzo: address.indirizzo ? address.indirizzo.toUpperCase() : '', //D
          citta: address.citta ? address.citta.toUpperCase() : '', //E
          provincia: address.provincia ? address.provincia.toUpperCase() : '', //F
          cap: address.cap, //G
          p_iva: p_iva[1] ? p_iva[1] : p_iva[0], //H
          codice_fiscale: entity.codice_fiscale ? entity.codice_fiscale : '', //I
          codice_pagamento: entity.condizioni_pagamento, //J
          codice_destinatario: entity.codice_univoco ? entity.codice_univoco : '', //K
          codice_pec: entity.pec ? entity.pec : '', //L
          codice_trasmissione: elem.codice_interno, //M
          numero_bolla: elem.trasmissioni_righe[0]?.bolla ? elem.trasmissioni_righe[0].bolla.numero : '', //N
          data_bolla: elem.trasmissioni_righe[0].bolla ? this.convertDateFormat(elem.trasmissioni_righe[0].bolla.data) : '', //O
        }

        csvRows.push(Object.values(trasmissione).join(';'));

        if (elem.trasmissioni_righe.length) {
          elem.trasmissioni_righe.forEach(row => {
            //console.log(row.lotto_rev.scadenza)
            //console.log(row.bolla?.data)
            console.log(row)

            if (row.bolla) {
              const article = row.riga.articolo_rev;
              const riga = {
                type: 'R', //A
                client_code: entity.codice_interno, //B
                codice_articolo: article.aic ? article.aic : article.ex_code ? article.ex_code : article.codice_interno, //C
                descrizione: article.description, //D
                aic: article.aic, //E
                quantita: row.quantita_allestita, //F
                price: +row.riga.prezzo, //G
                valore: Math.round((row.quantita_allestita * (+row.riga.prezzo)) * 100) / 100, //H
                aliquota: article.iva, //I
                riga: row.riga.codice_interno,//J
                numero_bolla: row?.bolla ? row.bolla.numero : '', //K
                data_bolla: row.bolla ? this.convertDateFormat(row.bolla.data) : '', //L
                riferimento_ordine: row.riga.ordine.riferimento_ordine, //M
                lotto: row.lotto_rev.codice_fornitore, //N
                scadenza: row.lotto_rev.scadenza ? this.convertDateFormat(row.lotto_rev.scadenza) : '', //O
                dataOrdine: row.riga.ordine.data ? this.convertDateFormat(row.riga.ordine.data) : '' //P
              }
              console.log(riga)

              csvRows.push(Object.values(riga).join(';'));
            }
          })
        }
      })

      this.downloadCustomCSV(csvRows.join('\n'));
    }
  }

  downloadCustomCSV(data: string) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.href = url;
    a.download = `export_${new Date().toLocaleDateString()}.csv`;
    a.click();
  }

  markAsFatturata() {
    let elemsID = this.selectedElements.map(elem => { return elem.id });
    this.updateTransfersStatus.emit(elemsID);
  }

  convertDateFormat(dateStr: string): string {
    if (dateStr) {
      // Dividi la stringa in parti
      const [year, month, day] = dateStr.split('-');
      // Ritorna la data nel formato desiderato
      return `${day}-${month}-${year}`;
    } else {
      return ''
    }
  }

  checkRoles(excludedRoles?: string[]) {
    let hideThis = false;

    if (excludedRoles && excludedRoles.length) {
      if (excludedRoles?.includes(this.currentHighestRole.value)) {
        hideThis = true;
      }
    }

    return hideThis;
  }
}
