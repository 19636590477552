<div class="export-buttons">
  <div class="quick-actions">
    <button *ngIf="selectedElements.length && showTransferStatusButton" mat-mini-fab color="accent"
      matTooltip="SEGNA COME FATTURATA" class="my-1" (click)="markAsFatturata()">
      <mat-icon class="col-white">playlist_add_check</mat-icon>
    </button>

    <button *ngIf="selectedElements.length" mat-mini-fab color="accent" matTooltip="EXPORT SELEZIONATE - CSV"
      class="mx-4 my-1" (click)="generateCustomCSV()">
      <mat-icon class="col-white">system_update_alt</mat-icon>
    </button>
  </div>
  <div *ngIf="showExportButtons">
    <button mat-mini-fab color="primary" matTooltip="EXPORT XLS" class="mx-2 my-1"
      (click)="exporter.exportTable('xlsx', {fileName:'export-xls-' + tableName.toLowerCase(), sheet: tableName, columnWidths: exportColumnWidths})">
      <mat-icon class="col-white">system_update_alt</mat-icon>
    </button>

    <button mat-mini-fab color="primary" matTooltip="EXPORT CSV" class="mx-2 my-1"
      (click)="exporter.exportTable('csv', {fileName:'export-csv-' + tableName.toLowerCase(), sheet: tableName})">
      <mat-icon class="col-white">system_update_alt</mat-icon>
    </button>

    <button *ngIf="currentHighestRole?.writePermissions" mat-mini-fab color="primary" matTooltip="EXPORT ALL CSV" class="mx-2 my-1" (click)="exportClicked.emit()">
      <mat-icon class="col-white">system_update_alt</mat-icon>
    </button>
  </div>
</div>
<div class="table-responsive">
  <table class="table" mat-table #table matTableExporter [dataSource]="data[this.currentIndex]" matSort
    #exporter="matTableExporter" (matSortChange)="simpleSort($event)" id="exporter-table"
    [hiddenColumns]="exportHiddenColumns">
    <ng-container *ngFor="let column of _configuration, index as i">
      <!-- PLAIN_TEXT Column -->
      <ng-container [matColumnDef]="column.id" *ngIf="column.type === 'PLAIN_TEXT'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.sortable" matTooltipPosition="above"
          [matTooltip]="(column.tooltip && column.tooltip != '' ? column.tooltip : '')"
          [ngClass]="column.headerClassList"
          [class.d-sm-table-cell]="!column.showMobile && !column.headerClassList.includes('d-none') && !checkRoles(column.excludedRoles)"
          [class.d-none]="!column.showMobile || checkRoles(column.excludedRoles)">
          {{column.header}}</th>
        <td mat-cell *matCellDef="let row" [ngClass]="column.fieldClassList"
          [class.d-sm-table-cell]="!column.showMobile && !column.headerClassList.includes('d-none') && !checkRoles(column.excludedRoles)"
          [class.d-none]="!column.showMobile || checkRoles(column.excludedRoles)">{{ column.hasTransformer ?
          column.transformFieldFunction(row) : row[column.fieldName]}}</td>
      </ng-container>
      <!-- HTML_TEXT Column -->
      <ng-container [matColumnDef]="column.id" *ngIf="column.type === 'HTML_TEXT'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.sortable" matTooltipPosition="above"
          [matTooltip]="(column.tooltip && column.tooltip != '' ? column.tooltip : '')"
          [ngClass]="column.headerClassList"
          [class.d-sm-table-cell]="!column.showMobile && !column.headerClassList.includes('d-none') && !checkRoles(column.excludedRoles)"
          [class.d-none]="!column.showMobile || checkRoles(column.excludedRoles)">
          {{column.header}}</th>
        <td mat-cell *matCellDef="let row" [ngClass]="column.fieldClassList"
          [class.d-sm-table-cell]="!column.showMobile && !column.fieldClassList.includes('d-none')"
          [class.d-none]="!column.showMobile || checkRoles(column.excludedRoles)" [innerHtml]="column.hasTransformer ?
      column.transformFieldFunction(row) : row[column.fieldName]"></td>
      </ng-container>
      <!-- STATUS Column -->
      <ng-container [matColumnDef]="column.id" *ngIf="column.type === 'STATUS'">
        <th class="header-center" mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="column.headerClassList"
          matTooltipPosition="above" [matTooltip]="(column.tooltip && column.tooltip != '' ? column.tooltip : '')"
          [class.d-sm-table-cell]="!column.showMobile && !column.headerClassList.includes('d-none') && !checkRoles(column.excludedRoles)"
          [class.d-none]="!column.showMobile || checkRoles(column.excludedRoles)">{{column.header}}</th>
        <td class="text-center" mat-cell *matCellDef="let row"
          [class.d-sm-table-cell]="!column.showMobile && !column.fieldClassList.includes('d-none')"
          [class.d-none]="!column.showMobile || checkRoles(column.excludedRoles)">
          <span
            *ngIf="column.hasTransformer ? column.transformFieldFunction(row) == 'registred' : row[column.fieldName] == 'registred'"
            class="badge col-black shadow-style">Registered</span>
          <span
            *ngIf="column.hasTransformer ? column.transformFieldFunction(row) == 'tbc' : row[column.fieldName] == 'tbc'"
            class="badge col-gray shadow-style">To be confirmed</span>
          <span
            *ngIf="column.hasTransformer ? column.transformFieldFunction(row) == 'confirmed' : row[column.fieldName] == 'confirmed'"
            class="badge col-orange shadow-style">Confirmed</span>
          <span
            *ngIf="column.hasTransformer ? column.transformFieldFunction(row) == 'confermato' : row[column.fieldName] == 'confermato'"
            class="badge col-orange shadow-style">Confermato</span>

          <span
            *ngIf="column.hasTransformer ? column.transformFieldFunction(row) == 'completa' : row[column.fieldName] == 'completa'"
            class="badge col-green shadow-style">Completa</span>
          <span
            *ngIf="column.hasTransformer ? column.transformFieldFunction(row) == 'evaso' : row[column.fieldName] == 'evaso'"
            class="badge col-green shadow-style">Evaso</span>
          <span
            *ngIf="column.hasTransformer ? column.transformFieldFunction(row) == 'completata' : row[column.fieldName] == 'completata'"
            class="badge col-green shadow-style">Completata</span>
          <span
            *ngIf="column.hasTransformer ? column.transformFieldFunction(row) == 'annullata' : row[column.fieldName] == 'annullata'"
            class="badge col-red shadow-style">Annullata</span>
          <span
            *ngIf="column.hasTransformer ? column.transformFieldFunction(row) == 'annullato' : row[column.fieldName] == 'annullato'"
            class="badge col-red shadow-style">Annullato</span>

          <span
            *ngIf="column.hasTransformer ? column.transformFieldFunction(row) == 'notAvailable' : row[column.fieldName] == 'notAvailable'"
            class="badge col-red shadow-style">Not
            available</span>
          <span
            *ngIf="column.hasTransformer ? column.transformFieldFunction(row) == 'tba' : row[column.fieldName] == 'tba'"
            class="badge col-blue shadow-style">To be approved</span>
          <span
            *ngIf="column.hasTransformer ? column.transformFieldFunction(row) == 'parziale' : row[column.fieldName] == 'parziale'"
            class="badge col-blue shadow-style">Parziale</span>
          <span
            *ngIf="column.hasTransformer ? column.transformFieldFunction(row) == 'inProgress' : row[column.fieldName] == 'inProgress'"
            class="badge col-yellow shadow-style">In
            progress</span>

          <span
            *ngIf="column.hasTransformer ? column.transformFieldFunction(row) == 'pending' : row[column.fieldName] == 'pending'"
            class="badge col-pink shadow-style">Pending</span>
          <span
            *ngIf="column.hasTransformer ? column.transformFieldFunction(row) == 'new' : row[column.fieldName] == 'new'"
            class="badge col-purple shadow-style">New</span>
          <span
            *ngIf="column.hasTransformer ? column.transformFieldFunction(row) == 'nuova' : row[column.fieldName] == 'nuova'"
            class="badge col-purple shadow-style">Nuova</span>
          <span
            *ngIf="column.hasTransformer ? column.transformFieldFunction(row) == 'bozza' : row[column.fieldName] == 'bozza'"
            class="badge col-gray shadow-style">Bozza</span>
          <span
            *ngIf="column.hasTransformer ? column.transformFieldFunction(row) == 'confermata' : row[column.fieldName] == 'confermata'"
            class="badge col-orange shadow-style">Confermata</span>
          <span
            *ngIf="column.hasTransformer ? column.transformFieldFunction(row) == 'trasmessa' : row[column.fieldName] == 'trasmessa'"
            class="badge col-yellow shadow-style">Trasmessa</span>
          <span
            *ngIf="column.hasTransformer ? column.transformFieldFunction(row) == 'ricevuta' : row[column.fieldName] == 'ricevuta'"
            class="badge col-green shadow-style">Ricevuta</span>
          <span
            *ngIf="column.hasTransformer ? column.transformFieldFunction(row) == 'spedita' : row[column.fieldName] == 'spedita'"
            class="badge col-pink shadow-style">Spedita</span>
          <span
            *ngIf="column.hasTransformer ? column.transformFieldFunction(row) == 'fatturata' : row[column.fieldName] == 'fatturata'"
            class="badge col-purple shadow-style">Fatturata</span>
        </td>
      </ng-container>
      <!-- BOOL Column -->
      <ng-container [matColumnDef]="column.id" *ngIf="column.type === 'BOOL'">
        <th class="header-center" [ngClass]="column.headerClassList" mat-header-cell *matHeaderCellDef mat-sort-header
          [ngClass]="column.headerClassList" matTooltipPosition="above"
          [matTooltip]="(column.tooltip && column.tooltip != '' ? column.tooltip : '')"
          [class.d-sm-table-cell]="!column.showMobile && !column.headerClassList.includes('d-none') && !checkRoles(column.excludedRoles)"
          [class.d-none]="!column.showMobile || checkRoles(column.excludedRoles)">{{column.header}}</th>
        <td class="text-center" [ngClass]="column.fieldClassList" mat-cell *matCellDef="let row"
          [class.d-sm-table-cell]="!column.showMobile && !column.fieldClassList.includes('d-none')"
          [class.d-none]="!column.showMobile || checkRoles(column.excludedRoles)">
          <span
            *ngIf="column.hasTransformer ? column.transformFieldFunction(row) == true : row[column.fieldName] == true"
            class="badge col-black shadow-style">Si</span>
          <span
            *ngIf="column.hasTransformer ? column.transformFieldFunction(row) == false : row[column.fieldName] == false"
            class="badge col-gray shadow-style">No</span>
        </td>
      </ng-container>
      <!-- LOADER Column -->
      <ng-container [matColumnDef]="column.id" *ngIf="column.type === 'LOADER'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.sortable" matTooltipPosition="above"
          [matTooltip]="(column.tooltip && column.tooltip != '' ? column.tooltip : '')"
          [ngClass]="column.headerClassList"
          [class.d-sm-table-cell]="!column.showMobile && !column.headerClassList.includes('d-none') && !checkRoles(column.excludedRoles)"
          [class.d-none]="!column.showMobile || checkRoles(column.excludedRoles)">
          {{column.header}}</th>
        <td [ngClass]="column.fieldClassList" mat-cell *matCellDef="let row"
          [class.d-sm-table-cell]="!column.showMobile && !column.fieldClassList.includes('d-none')"
          [class.d-none]="!column.showMobile || checkRoles(column.excludedRoles)">
          <!-- {{ row | HttpCallPipe: column.loaderOption | async}} -->
          <div *ngIf="row | HttpCallPipe: column.loaderOption | async as orcSum; else other_content">
            <div>{{ orcSum }}</div>
          </div>
          <ng-template #other_content>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </ng-template>

          <!-- <div *ngIf="(row | HttpCallPipe: column.loaderOption | async) >= 0; else other_content">
            
        </div> -->



        </td>
      </ng-container>
      <!-- ACTION Column -->
      <ng-container [matColumnDef]="column.id" *ngIf="column.type === 'ACTION' && column.id !== 'actions_articles'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.sortable" matTooltipPosition="above"
          [matTooltip]="(column.tooltip && column.tooltip != '' ? column.tooltip : '')"
          [ngClass]="column.headerClassList"
          [class.d-sm-table-cell]="!column.showMobile && !column.headerClassList.includes('d-none') && !checkRoles(column.excludedRoles)"
          [class.d-none]="!column.showMobile || checkRoles(column.excludedRoles)">
          {{column.header}}</th>
        <td mat-cell *matCellDef="let row; let i=index;" [ngClass]="column.fieldClassList"
          [class.d-sm-table-cell]="!column.showMobile && !column.fieldClassList.includes('d-none')"
          [class.d-none]="!column.showMobile || checkRoles(column.excludedRoles)">
          <span mat-icon-button [ngClass]="column.buttonClassList" *ngFor="let action of column.actions"
            (click)="action.performAction($event, column.id, i, row)">
            <a *ngIf="action.actionIcon === 'edit' && !checkRoles(action.excludedRoles)" [routerLink]="action.performAction($event, column.id, i, row)"
              style="color: black;">
              <mat-icon matSuffix>{{action.actionIcon}}</mat-icon>
            </a>

            <mat-icon matSuffix *ngIf="action.actionIcon !== 'edit' && !checkRoles(action.excludedRoles)">{{action.actionIcon}}</mat-icon>
          </span>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="column.id" *ngIf="column.type === 'ACTION' && column.id === 'actions_articles'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.sortable" matTooltipPosition="above"
          [matTooltip]="(column.tooltip && column.tooltip != '' ? column.tooltip : '')"
          [ngClass]="column.headerClassList"
          [class.d-sm-table-cell]="!column.showMobile && !column.headerClassList.includes('d-none') && !checkRoles(column.excludedRoles)"
          [class.d-none]="!column.showMobile || checkRoles(column.excludedRoles)">
          {{column.header}}</th>
        <td mat-cell *matCellDef="let row; let i=index;" [ngClass]="column.fieldClassList"
          [class.d-sm-table-cell]="!column.showMobile && !column.fieldClassList.includes('d-none')"
          [class.d-none]="!column.showMobile || checkRoles(column.excludedRoles)">
          <span mat-icon-button [ngClass]="column.buttonClassList" *ngFor="let action of column.actions"
            (click)="action.performAction($event, column.id, i, row)">
            <a *ngIf="action.actionIcon === 'edit' && ((row.prodotto && !checkRoles(action.excludedRoles)) || (!checkRoles(action.excludedRoles) && !currentHighestRole?.writePermissions))"
              [routerLink]="action.performAction($event, column.id, i, row)" style="color: black;">
              <mat-icon matSuffix>{{action.actionIcon}}</mat-icon>
            </a>

            <a *ngIf="action.actionIcon === 'edit' && !row.prodotto && !checkRoles(action.excludedRoles) && currentHighestRole?.writePermissions" style="color: black;">
              <mat-icon matSuffix>{{action.actionIcon}}</mat-icon>
            </a>

            <mat-icon matSuffix *ngIf="action.actionIcon !== 'edit' && !checkRoles(action.excludedRoles)">{{action.actionIcon}}</mat-icon>
          </span>
        </td>
      </ng-container>

      <!-- CURRENCY Column -->
      <ng-container [matColumnDef]="column.id" *ngIf="column.type === 'CURRENCY'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.sortable" matTooltipPosition="above"
          [matTooltip]="(column.tooltip && column.tooltip != '' ? column.tooltip : '')"
          [ngClass]="column.headerClassList"
          [class.d-sm-table-cell]="!column.showMobile && !column.headerClassList.includes('d-none') && !checkRoles(column.excludedRoles)"
          [class.d-none]="!column.showMobile || checkRoles(column.excludedRoles)">{{ column.header }}</th>
        <td mat-cell *matCellDef="let row; let i=index;" [ngClass]="column.fieldClassList"
          [class.d-sm-table-cell]="!column.showMobile && !column.fieldClassList.includes('d-none')"
          [class.d-none]="!column.showMobile || checkRoles(column.excludedRoles)">
          {{(column.hasTransformer ?
          column.transformFieldFunction(row) :
          row[column.fieldName]) | currency:'EUR' : 'symbol' : '1.0-2'}}
        </td>
      </ng-container>

      <!-- SELECTION Column -->
      <ng-container [matColumnDef]="column.id" *ngIf="column.type === 'SELECTION'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.sortable" matTooltipPosition="above"
          [matTooltip]="(column.tooltip && column.tooltip != '' ? column.tooltip : '')"
          [ngClass]="column.headerClassList"
          [class.d-sm-table-cell]="!column.showMobile && !column.headerClassList.includes('d-none') && !checkRoles(column.excludedRoles)"
          [class.d-none]="!column.showMobile || checkRoles(column.excludedRoles)">{{column.header}}</th>
        <td mat-cell *matCellDef="let row; let i=index;" [ngClass]="column.fieldClassList"
          [class.d-sm-table-cell]="!column.showMobile && !column.fieldClassList.includes('d-none')"
          [class.d-none]="!column.showMobile || checkRoles(column.excludedRoles)">
          <mat-checkbox (change)="selectRow($event.checked, row)" [checked]="row.rowSelected"></mat-checkbox>
          <!-- <input type="checkbox" (change)="selectRow($event.target.checked, row)" [checked]="row.rowSelected"> -->
        </td>
      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [style.cursor]="'pointer'">
    </tr>
  </table>
</div>

<mat-paginator #paginator [length]="totalResults" [pageSizeOptions]="pageSizeOption" [pageIndex]="0" [pageSize]="20"
  [showFirstLastButtons]="true" (page)="setPagination($event)">
</mat-paginator>